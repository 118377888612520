
import { defineComponent, PropType, ref, computed } from 'vue';
import OverlayModal from './OverlayModal.vue';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { ProductListItem } from '@/models/Product';
import { CalendarSlot } from '@/models/Calendar';
import { OrderDetails, OrderProductCustomerDetails, OrderProductDetails, Slot } from '@/models/Order';
import { generalStore } from '@/store';
import useOrder from '@/modules/useOrder';
import { PriceType, IdText, BussinesClientByNewOrderObject } from '@/models/Interfaces';
import useOrderCustomers from '@/modules/useOrderCustomers';
import mixin from '@/mixin';
import { api } from '@/services/Api';
import Dropdown from 'primevue/dropdown';
import { CouponLimitationType } from '@/models/Enums';
import OrderDataView from '@/components/Order/OrderDataView.vue';
import OrderProductPrice from '@/components/Order/OrderProductPrice.vue';
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
    components: { OverlayModal, OrderDataView, OrderProductPrice, Dropdown, InputNumber },
    name: 'CalendarOrderModal',
    props: {
        products: {
            type: Array as PropType<ProductListItem[]>,
            default: () => []
        }
    },
    emits: ['save', 'close', 'reloadSlots'],
    data() {
        return {};
    },
    async setup(props, { emit }) {
        const { t } = useI18n();
        const canChangeUnitPrice = generalStore.getters.canChangeUnitPrice;
        const couponLimitationType = CouponLimitationType;
        const clients = ref<BussinesClientByNewOrderObject[]>([]);
        const formElementRef = ref();
        const wasValidated = ref(false);
        const slot = ref(new CalendarSlot());
        const incorrectMobile = ref(false);
        const incorrectEmail = ref(false);
        const creatingBlocked = ref(false);
        const isOpen = ref(false);
        const order = ref(new OrderDetails());
        const dateToTitle = computed<string>(() => mixin.methods.getDateToTitle(order.value.newproduct.date));
        const product = computed(() => {
            return (
                (slot.value.productId && props.products.find(x => x.id == slot.value.productId)) || {
                    id: '',
                    name: 'Product not found',
                    color: ''
                }
            );
        });

        const { apiSaveOrder, getMinutes } = useOrder();
        const {
            createDebounce,
            dateToDisplay,
            customerTypes,
            calculated,
            qtyNotOversized,
            getCustomerPrice,
            getCustomerPriceCurrencySymbol,
            availableTypes,
            GetProductCustomers,
            GetValidatedCustomersOrError,
            CalculateEventProductCustomers,
            CalculateOnClickProductCustomers
        } = useOrderCustomers(order);

        const priceTypes = (await generalStore.dispatch('getPriceTypes')) as PriceType[];
        const priceTypeSelect = {
            value: 0,
            placeholder: '',
            valueProp: 'id',
            trackBy: 'text',
            label: 'text',
            required: true,
            searchable: true,
            canDeselect: false,
            closeOnSelect: true,
            options: priceTypes
        };

        const channels = (await generalStore.dispatch('getChannels')) as IdText[];
        const businessClientSelect = ref();

        const isGroupPrice = computed(() => {
            return mixin.methods.isGroupPrice(order.value.priceTypeId, priceTypes);
        });

        function setNewProductCustomers(add = false) {
            const newCustomer = new OrderProductCustomerDetails();
            newCustomer.date = order.value.newproduct.dateTime;
            const newSlot = new Slot();
            newSlot.id = slot.value.originalSlotId;
            newSlot.endTime = slot.value.endTime + ':00';
            newSlot.endTimeView = slot.value.endTime;
            newSlot.startTime = slot.value.startTime + ':00';
            newSlot.startTimeView = slot.value.startTime;
            newSlot.duration = slot.value.duration + ':00';
            newCustomer.slot = newSlot;
            if (add) {
                order.value.newproduct.customers.push(newCustomer);
            } else {
                order.value.newproduct.customers = [newCustomer];
            }
        }

        function onAddCustomer() {
            if (order.value.newproduct.customers.length < customerTypes.value.length) {
                setNewProductCustomers(true);
            }
        }
        const deleteRow = async (index: number) => {
            calculated.value = false;
            order.value.newproduct.customers.splice(index, 1);
            if (
                !order.value.newproduct.customers ||
                order.value.newproduct.customers.length === 0 ||
                order.value.newproduct.customers.some(x => !x.quantity || x.quantity == 0 || !x.id)
            ) {
                return;
            }
            await CalculateOnClickProductCustomers();
        };

        function mobileNumberChanged() {
            incorrectMobile.value = false;
        }
        function emailChanged() {
            incorrectEmail.value = false;
        }

        const back = () => {
            formElementRef.value.classList.remove('was-validated');
            wasValidated.value = false;
            incorrectMobile.value = false;
            emit('close');
            isOpen.value = false;
        };
        const save = async (event: any) => {
            wasValidated.value = true;
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                const customers = GetValidatedCustomersOrError();
                if (typeof customers == 'string') {
                    return null;
                }
                if (creatingBlocked.value) {
                    return;
                }
                swal.showLoading();
                creatingBlocked.value = true;
                order.value.products = [order.value.newproduct];
                order.value.currencyId = order.value.newproduct.currencyId;
                order.value.products[0].customers.forEach(customer => {
                    customer.couponId = customer.customerInfo.couponId;
                    customer.slotId = order.value.newproduct.slotId;
                });
                const orderResp = await apiSaveOrder(order.value, message => {
                    if (message?.includes('Invalid Mobile')) {
                        incorrectMobile.value = true;
                    }
                    if (message?.includes('Invalid CustomerEmail')) {
                        incorrectEmail.value = true;
                    }
                    return true;
                });
                if (!orderResp) {
                    creatingBlocked.value = false;
                    return;
                }
                emit('save', orderResp.orderId);
                emit('reloadSlots');
                creatingBlocked.value = false;
                back();
                swal.fire({
                    icon: 'success',
                    text: t('newOrder.savedMessage', { Number: orderResp.orderNumber })
                });
            }
        };
        async function open(newSlot: CalendarSlot) {
            const clientResult = await api.GetBussinesClientsByNewOrder();
            clients.value = clientResult.data?.businessClients || [];
            businessClientSelect.value = {
                placeholder: '',
                valueProp: 'id',
                trackBy: 'text',
                label: 'name',
                searchable: true,
                options: clients.value
            };
            customerTypes.value = [];
            slot.value = CalendarSlot.clone(newSlot);
            qtyNotOversized.value = false;
            calculated.value = false;
            order.value = new OrderDetails();
            const direct = channels.find(x => x.text.toLowerCase() == 'direct');
            if (direct) {
                order.value.channelId = direct.id;
            }
            order.value.newproduct = new OrderProductDetails();
            order.value.newproduct.productId = product.value.id;
            order.value.newproduct.productName = product.value.name;
            order.value.newproduct.calendarDate = dateToDisplay(slot.value.startDate);
            order.value.newproduct.slotId = slot.value.id || slot.value.originalSlotId;
            order.value.newproduct.slot = new Slot();
            order.value.newproduct.slot.id = order.value.newproduct.slotId;
            order.value.newproduct.slot.duration = newSlot.duration + ':00';
            order.value.newproduct.slot.startTime = slot.value.startTime + ':00';
            order.value.newproduct.slot.endTime = slot.value.endTime + ':00';
            order.value.newproduct.dateTime = `${newSlot.startDate}T${slot.value.startTime}:00`;
            order.value.newproduct.date = newSlot.startDate.toString();
            order.value.newproduct.startTime = slot.value.startTime + ':00';
            order.value.newproduct.duration = newSlot.duration + ':00';
            setNewProductCustomers();
            order.value.products = [order.value.newproduct];
            isOpen.value = true;
            emit('save', true);
        }
        async function getPriceCustomersOnPriceTypeChange(val: string) {
            setNewProductCustomers();
            order.value.priceTypeId = val;
            await GetProductCustomers();
        }

        function fillBussnessClientDetailsInOrder(id: number | null) {
            if (id == null) {
                order.value.leadCustomer.firstName = '';
                order.value.leadCustomer.lastName = '';
                order.value.leadCustomer.email = '';
                order.value.leadCustomer.mobile = '';
            } else {
                const client = clients.value.find(x => x.id == id);
                if (!client) {
                    return;
                }
                order.value.leadCustomer.firstName = client.contactFirstName;
                order.value.leadCustomer.lastName = client.contactLastName;
                order.value.leadCustomer.email = client.email;
                order.value.leadCustomer.mobile = client.mobile;
            }
        }
        async function onBussinessClientChange(businessClientId: number | null) {
            order.value.businessClientId = businessClientId;
            fillBussnessClientDetailsInOrder(businessClientId);
            if (!order.value.priceTypeId) {
                return;
            }
            setNewProductCustomers();
            await GetProductCustomers();
        }
        function isWarning(customer: OrderProductCustomerDetails) {
            if (
                calculated.value &&
                order.value.newproduct.notAvailableCustomerTypeIds &&
                order.value.newproduct.notAvailableCustomerTypeIds.length
            ) {
                return order.value.newproduct.notAvailableCustomerTypeIds.includes(customer.id);
            }
            return calculated.value && !order.value.newproduct.isAvailable;
        }
        function setDiscount(event: any, customer: OrderProductCustomerDetails) {
            customer.discountAmount = event.value ?? 0;
        }
        async function onChangeCustomerType(event: any, customer: OrderProductCustomerDetails) {
            calculated.value = false;
            customer.discountAmount = 0;
            customer.priceForOne = getCustomerPrice(event.value.id);
            CalculateEventProductCustomers(customer);
        }
        async function onInputPriceForOne(event: any, customer: OrderProductCustomerDetails) {
            customer.priceForOne = event.value;
            customer.limitExceeded = false;
            calculated.value = false;
        }
        return {
            back,
            order,
            product,
            slot,
            isOpen,
            open,
            calculated,
            qtyNotOversized,
            onAddCustomer,
            deleteRow,
            debounce: createDebounce(),
            getCustomerPrice,
            getCustomerPriceCurrencySymbol,
            clients,
            priceTypes,
            priceTypeSelect,
            incorrectMobile,
            mobileNumberChanged,
            incorrectEmail,
            emailChanged,
            availableTypes,
            save,
            getPriceCustomersOnPriceTypeChange,
            onBussinessClientChange,
            isWarning,
            wasValidated,
            formElementRef,
            isGroupPrice,
            channels,
            businessClientSelect,
            CalculateEventProductCustomers,
            couponLimitationType,
            useOrderCustomers,
            dateToTitle,
            getMinutes,
            setDiscount,
            canChangeUnitPrice,
            onChangeCustomerType,
            onInputPriceForOne
        };
    }
});
